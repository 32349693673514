// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */

import * as React from "react";
import { createUseScreenVariants } from "@plasmicapp/react-web";

export type LngValue = "ru" | "en" | "be";
export const LngContext = React.createContext<LngValue | undefined>(
  "PLEASE_RENDER_INSIDE_PROVIDER" as any
);

export function useLng() {
  return React.useContext(LngContext);
}

export default LngContext;
/* prettier-ignore-end */
