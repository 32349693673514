// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type LogoIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function LogoIcon(props: LogoIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 66 56"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M26.617 23.355V5.631l-5.311 1.496V2.11L26.134.66h6.398v22.694h32.653l-4.346 5.191h-3.62l-8.632 16.9v10.381h-5.553v-10.38l-8.812-16.9h-1.69l9.054 27.281h-5.674L33.74 49.55h-8.027l-1.931 6.277h-8.39V36.875l-4.165 7.062h-.905l-5.01-7.062.363 18.952H0V23.355s9.888-9.707 10.883-10.93c.994-1.223 2.839-4.552.357-6.856-2.726-2.53-7.288 0-10.432 1.24V1.627S4.173.841 5.482.606C6.792.371 8.797 0 9.936 0c1.187 0 2.314.253 3.33.709a8.159 8.159 0 014.827 7.448 8.124 8.124 0 01-1.904 5.24l.013.012-9.389 9.946h19.804zm-11.56 5.191H6.314l4.448 7.079 4.295-7.079zm6.143 0v17.669l5.659-17.669h-5.66zm5.659 16.9h5.673l-2.747-9.472-2.926 9.472zm24.42-16.9l-5.263 10.81-5.155-10.81h10.418z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default LogoIcon;
/* prettier-ignore-end */
